import { createHttpEndpoint } from '../../utils'

import type { GetFieldsValidatorConfigResponse } from './address-validation.types'

/**
 *  Get address validation rules
 *
 * @see {@link }
 */
export const getAddressValidationRules =
  createHttpEndpoint<GetFieldsValidatorConfigResponse>({
    method: 'GET',
    operationId: 'GetAddressValidationRules',
    path: '/bm/shipping/v1/address-validation-rules',
  })
