import { useAsyncData, useRuntimeConfig } from '#imports'

import { getAddressValidationRules } from '@backmarket/http-api/src/api-specs-shipping/address/address-validation'
import type {
  AddressFieldsValues,
  FieldsValidatorConfigItem,
  GetFieldsValidatorConfigResponse,
  RuleItem,
} from '@backmarket/http-api/src/api-specs-shipping/address/address-validation.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import type { I18nValues } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { merge } from '@backmarket/utils/object/merge'
import { omit } from '@backmarket/utils/object/omit'
import {
  FORM_VALID,
  type ValidationResult,
  type Validator,
  type ValidatorDeclaration,
  booleanValue,
  makeValidate,
  matchingRegExp,
  maxLength,
  minLength,
  missingRegExp,
  range,
  required,
} from '@ds/components/Form'

import dynamicAddressFieldsValidatorCustom from '../translations/dynamicAddressFieldsValidatorCustom.translations'
import dynamicAddressFieldsValidatorDefault from '../translations/dynamicAddressFieldsValidatorDefault.translations'
import type {
  AddressValidationMetadata,
  AddressValidationTranslationKey,
} from '../types/fieldsValidator'

/**
 * Predicate to check if a rule is an object with a value and an errorMsgKey
 */
export function isRuleObject<FieldType>(
  arg: FieldType | { value: FieldType; errorMsgKey?: string },
): arg is { value: FieldType; errorMsgKey: string } {
  return typeof arg === 'object' && arg !== null && 'value' in arg
}

/**
 * Extracts the value from a rule
 */
export function getRuleValue<FieldType>(
  ruleValue: RuleItem<FieldType>,
): FieldType {
  return isRuleObject(ruleValue) ? ruleValue.value : ruleValue
}

/**
 * This function returns the translation for a rule error, if a custom translation is provided it will be used
 * @param defaultTranslationKey translation key to use if no custom translation is provided
 * @param ruleValue the rule value to use to get the custom translation
 * @param metadata the metadata to use to get the custom translation (e.g. length for minLength, maxLength, etc.)
 */
export function getRuleErrorTranslation<FieldType>({
  i18n,
  defaultTranslationKey,
  ruleValue,
  metadata = {},
}: {
  i18n: ReturnType<typeof useI18n>
  defaultTranslationKey: keyof typeof dynamicAddressFieldsValidatorDefault
  ruleValue: RuleItem<FieldType>
  metadata?: I18nValues
}) {
  if (isRuleObject(ruleValue)) {
    return i18n(
      dynamicAddressFieldsValidatorCustom[
        ruleValue.errorMsgKey as AddressValidationTranslationKey
      ] || dynamicAddressFieldsValidatorDefault[defaultTranslationKey],
      metadata,
    )
  }

  return i18n(
    dynamicAddressFieldsValidatorDefault[defaultTranslationKey],
    metadata,
  )
}

/**
 * This function returns the config to use based on the selected country
 * If no country is selected or if no overrides are provided for the selected country, it will return the global config
 */
export function getConfigToUse(
  ruleConfig: Required<GetFieldsValidatorConfigResponse>,
  selectedCountry: AddressFieldsValues['country'],
) {
  if (!selectedCountry || !ruleConfig.overridesByCountry[selectedCountry]) {
    return ruleConfig.global
  }

  return merge(
    {},
    ruleConfig.global,
    ruleConfig.overridesByCountry[selectedCountry],
  )
}

/**
 * This function applies the dynamic rules to the field rules
 */
export function applyDynamicRuleToConfig({
  fieldRules,
  values,
  metadata = {},
}: {
  fieldRules: FieldsValidatorConfigItem
  values: AddressFieldsValues
  metadata?: AddressValidationMetadata
}) {
  let mergedFieldRule = fieldRules

  if (fieldRules.dynamicRules && Array.isArray(fieldRules.dynamicRules)) {
    fieldRules.dynamicRules.forEach((dynamicRule) => {
      // Merge values and metadata to be able to use them in the dynamic rules
      const valuesWithMetada = {
        ...values,
        ...metadata,
      }
      if (valuesWithMetada[dynamicRule.if.field] === dynamicRule.if.value) {
        mergedFieldRule = {
          ...mergedFieldRule,
          ...dynamicRule.then,
        }
      }
    })
  }

  return omit(mergedFieldRule, 'dynamicRules')
}
/**
 * This function builds a custom validator based on the rules provided by the address validation API
 */
export function buildCustomValidator({
  i18n,
  ruleConfig,
  fallbackValidators,
  fieldName,
  metadata = {},
}: {
  i18n: ReturnType<typeof useI18n>
  ruleConfig: Required<GetFieldsValidatorConfigResponse>
  fallbackValidators: ValidatorDeclaration<AddressFieldsValues>
  fieldName: keyof AddressFieldsValues
  metadata?: AddressValidationMetadata
}): Validator<AddressFieldsValues> {
  return (value, values: AddressFieldsValues): ValidationResult => {
    const configToUse = getConfigToUse(ruleConfig, values.country)
    const fieldRules = configToUse[fieldName]
    const trimmedValue = typeof value === 'string' ? value.trim() : value

    // If no rules are in the config we check the fallback validators
    if (!fieldRules) {
      return fallbackValidators[fieldName]
        ? makeValidate(fallbackValidators)(
            fieldName,
            trimmedValue as string,
            values,
          )
        : FORM_VALID
    }

    const mergedFieldRule = applyDynamicRuleToConfig({
      fieldRules,
      values,
      metadata,
    })

    // Required validation
    const requiredRule = mergedFieldRule.required
    if (requiredRule && getRuleValue(requiredRule) === true) {
      const requiredResult = required(
        getRuleErrorTranslation<FieldsValidatorConfigItem['required']>({
          i18n,
          defaultTranslationKey: 'required',
          ruleValue: mergedFieldRule.required,
        }),
      )(trimmedValue, values, {})
      if (requiredResult !== FORM_VALID) {
        return requiredResult
      }
    }

    // Min length validation
    const minLengthRule = mergedFieldRule.minLength
    if (minLengthRule && Number(getRuleValue(minLengthRule))) {
      const lengthValue = Number(getRuleValue(minLengthRule))
      const minLengthResult = minLength(
        lengthValue,
        getRuleErrorTranslation<FieldsValidatorConfigItem['minLength']>({
          i18n,
          defaultTranslationKey: 'fieldTooShort',
          ruleValue: mergedFieldRule.minLength,
          metadata: {
            length: lengthValue,
          },
        }),
      )(trimmedValue, values, {})
      if (minLengthResult !== FORM_VALID) {
        return minLengthResult
      }
    }

    // Max length validation
    const maxLengthRule = mergedFieldRule.maxLength
    if (maxLengthRule && Number(getRuleValue(maxLengthRule))) {
      const lengthValue = Number(getRuleValue(maxLengthRule))
      const maxLengthResult = maxLength(
        lengthValue,
        getRuleErrorTranslation<FieldsValidatorConfigItem['maxLength']>({
          i18n,
          defaultTranslationKey: 'fieldTooLong',
          ruleValue: mergedFieldRule.maxLength,
          metadata: {
            length: lengthValue,
          },
        }),
      )(trimmedValue, values, {})
      if (maxLengthResult !== FORM_VALID) {
        return maxLengthResult
      }
    }

    // Disallowed patterns validation
    const disallowedPatternsRule = mergedFieldRule.disallowedPatterns
    if (disallowedPatternsRule && Array.isArray(disallowedPatternsRule)) {
      const disallowedPatternsResults = disallowedPatternsRule.map(
        (disallowedPattern) => {
          const patternValue = isRuleObject(disallowedPattern)
            ? {
                value: disallowedPattern.value,
                flags: disallowedPattern.flags || [],
                errorMsgKey: disallowedPattern.errorMsgKey,
              }
            : {
                value: disallowedPattern,
                flags: [],
              }

          return missingRegExp(
            // nosemgrep: javascript.lang.security.audit.detect-non-literal-regexp.detect-non-literal-regexp
            new RegExp(patternValue.value, patternValue.flags.join('')),
            getRuleErrorTranslation<RuleItem<string>>({
              i18n,
              defaultTranslationKey: 'incorrectFormat',
              ruleValue: patternValue as RuleItem<string>,
            }),
          )(trimmedValue, values, {})
        },
      )
      // If any of the disallowed patterns is not valid, return the first one
      if (disallowedPatternsResults.some((result) => result !== FORM_VALID)) {
        return disallowedPatternsResults.find(
          (result) => result !== FORM_VALID,
        ) as ValidationResult
      }
    }

    // Pattern value validation
    const patternRule = mergedFieldRule.pattern
    if (patternRule && typeof getRuleValue(patternRule) === 'string') {
      const patternValue = isRuleObject(patternRule)
        ? {
            value: patternRule.value,
            flags: patternRule.flags || [],
            errorMsgKey: patternRule.errorMsgKey,
          }
        : {
            value: patternRule,
            flags: [],
          }

      const patternResult = matchingRegExp(
        // nosemgrep: javascript.lang.security.audit.detect-non-literal-regexp.detect-non-literal-regexp
        new RegExp(patternValue.value, patternValue.flags.join('')),
        getRuleErrorTranslation<FieldsValidatorConfigItem['pattern']>({
          i18n,
          defaultTranslationKey: 'incorrectFormat',
          ruleValue: fieldRules.pattern,
        }),
      )(trimmedValue, values, {})
      if (patternResult !== FORM_VALID) {
        return patternResult
      }
    }

    // Range value validation
    const rangeRule = mergedFieldRule.range
    if (rangeRule && getRuleValue(rangeRule)) {
      const rangeValue = getRuleValue(rangeRule)
      const rangeResult = range(
        rangeValue,
        getRuleErrorTranslation<FieldsValidatorConfigItem['range']>({
          i18n,
          defaultTranslationKey: 'notInTheRange',
          ruleValue: mergedFieldRule.range,
        }),
      )(trimmedValue, values, {})
      if (rangeResult !== FORM_VALID) {
        return rangeResult
      }
    }

    // Boolean value validation
    const booleanValueRule = mergedFieldRule.booleanValue
    if (
      booleanValueRule &&
      typeof getRuleValue(booleanValueRule) === 'boolean'
    ) {
      const booleanFieldValue = getRuleValue(booleanValueRule)
      const booleanValueResult = booleanValue(
        booleanFieldValue,
        getRuleErrorTranslation<FieldsValidatorConfigItem['booleanValue']>({
          i18n,
          defaultTranslationKey: 'wrongBooleanValue',
          ruleValue: mergedFieldRule.booleanValue,
        }),
      )(trimmedValue, values, {})
      if (booleanValueResult !== FORM_VALID) {
        return booleanValueResult
      }
    }

    // If no validation error, return FORM_VALID
    return FORM_VALID
  }
}

export async function fetchAddressValidatorConfig() {
  const { global, overridesByCountry } = await $httpFetch(
    getAddressValidationRules,
  )

  return {
    global: global || {},
    overridesByCountry: overridesByCountry || {},
  }
}

/**
 * This composable provides a way to get dynamic address field validators based on the country of the address
 * It uses the address validation API to get the rules for the address fields
 * It also provides a way to build a custom validator based on the rules
 * It provides two ways to get the validators:
 * - getDynamicAddressFieldValidators: a function to get the validators asynchronously
 * - getDynamicAddressFieldValidatorsWithAsyncData: a function to get the validators with useAsyncData
 */
export function useDynamicAddressFieldValidators() {
  const i18n = useI18n()
  /**
   * This function returns the dynamic address field validators based on the country of the address
   * @param i18n the i18n instance
   * @param initialFormValues the initial form values
   * @param fallbackValidators the fallback validators to use if the address validation API is not available or if no rules are provided for the address field
   * @param featureFlag the feature flag to use to check if the feature is enabled
   * @param metadata metadata that can be used in dynamic rules (for example { scope: 'seller' })
   */
  async function getDynamicAddressFieldValidators({
    initialFormValues,
    fallbackValidators,
    featureFlag,
    metadata = {},
  }: {
    initialFormValues: AddressFieldsValues
    fallbackValidators: ValidatorDeclaration<AddressFieldsValues>
    featureFlag: string
    metadata?: AddressValidationMetadata
  }): Promise<ValidatorDeclaration<AddressFieldsValues>> {
    try {
      const MARKET_COUNTRY_CODE = useMarketplace().market.countryCode
      const ALLOWED_COUNTRIES =
        (useRuntimeConfig().public[featureFlag as string] as string) || ''
      if (!ALLOWED_COUNTRIES.includes(MARKET_COUNTRY_CODE)) {
        return Promise.resolve(fallbackValidators)
      }
      const ruleConfig = await fetchAddressValidatorConfig()
      const fieldsToValidate = Object.keys(initialFormValues) as Array<
        keyof AddressFieldsValues
      >

      return fieldsToValidate.reduce((acc, fieldName) => {
        return {
          ...acc,
          [fieldName]: buildCustomValidator({
            i18n,
            ruleConfig,
            fallbackValidators,
            fieldName,
            metadata,
          }),
        }
      }, {})
    } catch (err) {
      const logger = useLogger()
      logger.error(
        '[SHIPPING][AddressValidationError] Errors while fetching the config',
        { error: err as Error },
      )

      return fallbackValidators
    }
  }

  /**
   * This function returns the dynamic address field validators based on the country of the address
   * It uses useAsyncData to provide pending, error and data states
   * @param i18n the i18n instance
   * @param initialFormValues the initial form values
   * @param fallbackValidators the fallback validators to use if the address validation API is not available or if no rules are provided for the address field
   * @param featureFlag the feature flag to use to check if the feature is enabled
   * @param metadata metadata that can be used in dynamic rules (for example { scope: 'seller' })
   */
  function getDynamicAddressFieldValidatorsWithAsyncData({
    initialFormValues,
    fallbackValidators,
    featureFlag,
    metadata = {},
  }: {
    initialFormValues: AddressFieldsValues
    fallbackValidators: ValidatorDeclaration<AddressFieldsValues>
    featureFlag: string
    metadata?: AddressValidationMetadata
  }) {
    return useAsyncData(
      'address-validator-config',
      (): Promise<ValidatorDeclaration<AddressFieldsValues>> => {
        return getDynamicAddressFieldValidators({
          initialFormValues,
          fallbackValidators,
          featureFlag,
          metadata,
        })
      },
      {
        server: false,
        default: () => fallbackValidators,
      },
    )
  }

  return {
    getDynamicAddressFieldValidators,
    getDynamicAddressFieldValidatorsWithAsyncData,
    buildCustomValidator,
  }
}
